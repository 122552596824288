<template>
  <div>
    <div class="h4  sp-mb-1">{{ $t("on_boarding.completed.title") }}</div>
    <div class="h6  sp-mb-2">{{ $t("on_boarding.completed.subtitle") }}</div>
    <p>{{ $t("on_boarding.completed.body") }}</p>

    <d-button small @click="onCheckStatus">{{ $t("on_boarding.check_status")}}</d-button>
  </div>
</template>

<script>
export default {
  name: "Completed"
}
</script>